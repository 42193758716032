import React, { BaseSyntheticEvent, FC } from 'react'
import { CompanySearch } from 'src/company'
import AddPortfolio from 'src/Components/Portfolio/AddPortfolio/AddPortfolio'
import { Link } from 'react-router-dom'

interface Props {
    id: string
    searchItem: CompanySearch
    onPortfolioCreate: (e: BaseSyntheticEvent) => void
}

const Card: FC<Props> = ({ id, searchItem, onPortfolioCreate }) => {
    return (
        <div
            className='flex flex-col items-center justify-between w-full p-6 bg-slate-100 rounded-lg md:flex-row'
            key={id}
            id={id}
        >
            <Link
                to={`/company/${searchItem.symbol}/company-profile`}
                className='font-bold text-center text-black md:text-left'
            >
                {searchItem.name} ({searchItem.symbol})
            </Link>
            <p className='text-black'>{searchItem.currency}</p>
            <p className='font-bold text-black'>
                {searchItem.exchangeShortName} - {searchItem.stockExchange}
            </p>
            <AddPortfolio onPortfolioCreate={onPortfolioCreate} symbol={searchItem.symbol} />
        </div>
    )
}

export default Card
