import React, { useEffect, useState } from 'react'
import { getCashFlowStatement } from 'src/api'
import { CompanyCashFlow } from 'src/company'
import { useOutletContext } from 'react-router-dom'
import Table from 'src/Components/Table/Table'
import Spinner from 'src/Components/Spinner/Spinner'
import { formatLargeMonetaryNumber } from 'src/Helpers/NumberFormatting'

const config = [
    {
        label: 'Date',
        render: (company: CompanyCashFlow) => company.date
    },
    {
        label: 'Operating Cashflow',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.operatingCashFlow)
    },
    {
        label: 'Investing Cashflow',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.netCashUsedForInvestingActivites)
    },
    {
        label: 'Financing Cashflow',
        render: (company: CompanyCashFlow) =>
            formatLargeMonetaryNumber(company.netCashUsedProvidedByFinancingActivities)
    },
    {
        label: 'Cash At End of Period',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.cashAtEndOfPeriod)
    },
    {
        label: 'CapEX',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.capitalExpenditure)
    },
    {
        label: 'Issuance Of Stock',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.commonStockIssued)
    },
    {
        label: 'Free Cash Flow',
        render: (company: CompanyCashFlow) => formatLargeMonetaryNumber(company.freeCashFlow)
    }
]

const CashFlowStatement = () => {
    const ticker = useOutletContext<string>()
    const [cashFlowStatement, setCashFlowStatement] = useState<CompanyCashFlow[]>()
    const [serverError, setServerError] = useState<string | null>(null)

    useEffect(() => {
        const getData = async () => {
            const result = await getCashFlowStatement(ticker)
            if (typeof result === 'string') {
                setServerError(result)
            } else if (Array.isArray(result.data)) {
                setServerError(null)
                setCashFlowStatement(result.data)
            }
        }
        getData()
    }, [])

    return (
        <>
            {serverError && <h1>{serverError}</h1>}
            {cashFlowStatement ? <Table config={config} data={cashFlowStatement} /> : <Spinner />}
        </>
    )
}

export default CashFlowStatement
