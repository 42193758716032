import axios from 'axios'
import { handleError } from 'src/Helpers/ErrorHandler'
import { CommentGet, CommentPost } from 'src/Models/Comment'

const url = `${process.env.REACT_APP_API_URL}/comments`

export const commentPostApi = async (title: string, content: string, symbol: string) => {
    try {
        const data = await axios.post<CommentPost>(`${url}/${symbol}`, {
            title,
            content
        })

        return data
    } catch (err) {
        handleError(err)
    }
}

export const commentGetApi = async (symbol: string) => {
    try {
        const data = await axios.get<CommentGet[]>(`${url}?Symbol=${symbol}`)

        return data
    } catch (err) {
        handleError(err)
    }
}
