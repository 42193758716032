import React from 'react'
import * as yup from 'yup'
import { userAuth } from 'src/Context/userAuth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'

type FormInput = {
    email: string
    username: string
    password: string
}

const schema = yup.object().shape({
    email: yup.string().required('Email is required'),
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required')
})

const RegisterPage = () => {
    const { registerUser } = userAuth()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormInput>({ resolver: yupResolver(schema) })

    const handleRegister = (input: FormInput) => {
        registerUser(input.email, input.username, input.password)
    }

    return (
        <section className='bg-gray-50 dark:bg-gray-900'>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <div className='w-full bg-white rounded-lg shadow dark:border md:mb-20 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
                    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                        <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
                            Sing up a new account
                        </h1>
                        <form className='space-y-4 md:space-y-6' onSubmit={handleSubmit(handleRegister)}>
                            <div>
                                <label
                                    htmlFor='email'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                >
                                    Email
                                </label>
                                <input
                                    type='text'
                                    id='email'
                                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    placeholder='Username'
                                    {...register('email')}
                                />
                                {errors.email ? <p>{errors.email.message}</p> : ''}
                            </div>
                            <div>
                                <label
                                    htmlFor='email'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                >
                                    Username
                                </label>
                                <input
                                    type='text'
                                    id='username'
                                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    placeholder='Username'
                                    {...register('username')}
                                />
                                {errors.username ? <p>{errors.username.message}</p> : ''}
                            </div>
                            <div>
                                <label
                                    htmlFor='password'
                                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                >
                                    Password
                                </label>
                                <input
                                    type='password'
                                    id='password'
                                    placeholder='••••••••'
                                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    {...register('password')}
                                />
                                {errors.password ? <p>{errors.password.message}</p> : ''}
                            </div>
                            <button
                                type='submit'
                                className='w-full bg-lightGreen hover:opacity-70 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
                            >
                                Sign up
                            </button>
                            <p className='text-sm font-light text-gray-500 dark:text-gray-400'>
                                Do you have an account?{' '}
                                <Link
                                    to='/login'
                                    className='font-medium text-primary-600 hover:underline dark:text-primary-500'
                                >
                                    Login
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RegisterPage
