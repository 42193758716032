import axios from 'axios'
import { toast } from 'react-toastify'

export const handleError = (err: any) => {
    if (axios.isAxiosError(err)) {
        const response = err.response

        if (Array.isArray(response?.data.errors)) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const val of response?.data.errors) {
                toast.warning(val.description)
            }
        } else if (typeof response?.data.errors === 'object') {
            for (const e in response?.data.errors) {
                toast.warning(response.data.errors[e][0])
            }
        } else if (response?.status === 500 && Array.isArray(response?.data)) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const val of response?.data) {
                if (val.code !== undefined && val.description !== undefined) {
                    toast.warning(val.description)
                }
            }
        } else if (response?.status === 401) {
            toast.warning('Please login')
            window.history.pushState({}, 'LoginPage', '/login')
        } else if (response?.data) {
            toast.warning(response.data)
        } else {
            toast.warning('Something went wrong')
        }
    }
}
