import React, { BaseSyntheticEvent, useEffect, useState } from 'react'
import 'src/App.css'
import CardList from 'src/Components/CardList/CardList'
import Search from 'src/Components/Search/Search'
import { CompanySearch } from 'src/company'
import { searchCompanies } from 'src/api'
import ListPortfolio from 'src/Components/Portfolio/ListPortfolio/ListPortfolio'
import NavBar from 'src/Components/NavBar/NavBar'
import { PortfolioGet } from 'src/Models/Portfolio'
import { portfolioAddApi, portfolioDeleteApi, portfolioGetApi } from 'src/Services/PortfolioService'
import { toast } from 'react-toastify'

const SearchPage = () => {
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchResult, setSearchResult] = useState<CompanySearch[]>([])
    const [portfolioValues, setPortfolioValues] = useState<PortfolioGet[]>([])
    const [serverError, setServerError] = useState<string | null>(null)

    useEffect(() => {
        getPortfolio()
    }, [])

    const getPortfolio = () => {
        portfolioGetApi()
            .then((res) => {
                if (res?.data) {
                    setPortfolioValues(res.data ?? [])
                }
            })
            .catch((err) => {
                toast.warning('Could not get portfolio values!')
            })
    }

    const handlePortfolioCreate = (e: BaseSyntheticEvent) => {
        e.preventDefault()
        portfolioAddApi(e.target[0].value)
            .then((res) => {
                if (res?.status === 204) {
                    toast.success('Stock added to portfolio!')
                    getPortfolio()
                }
            })
            .catch((err) => {
                toast.warning('Could not create portfolio item!')
            })
    }

    const handlePortfolioDelete = (e: BaseSyntheticEvent) => {
        e.preventDefault()
        portfolioDeleteApi(e.target[0].value)
            .then((res) => {
                if (res?.status === 204) {
                    toast.success('Stock deleted from portfolio!')
                    getPortfolio()
                }
            })
            .catch((err) => {
                toast.warning('Could not delete portfolio item!')
            })
    }

    const handleSearchValueChange = (e: BaseSyntheticEvent) => {
        setSearchValue(e.target.value)
    }

    const handleSearchSubmit = async (e: BaseSyntheticEvent) => {
        e.preventDefault()

        const result = await searchCompanies(searchValue)
        if (typeof result === 'string') {
            setServerError(result)
        } else if (Array.isArray(result.data)) {
            setServerError(null)
            setSearchResult(result.data)
        }
    }

    return (
        <>
            <Search
                searchValue={searchValue}
                onSearchSubmit={handleSearchSubmit}
                onSearchValueChange={handleSearchValueChange}
            />
            {serverError && <h1>{serverError}</h1>}
            <ListPortfolio portfolioValues={portfolioValues} onPortfolioDelete={handlePortfolioDelete} />
            <CardList searchResult={searchResult} onPortfolioCreate={handlePortfolioCreate} />
        </>
    )
}

export default SearchPage
