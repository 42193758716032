import React, { FC } from 'react'
import logo from './logo.png'
import { Link } from 'react-router-dom'
import { userAuth } from 'src/Context/userAuth'

const NavBar = () => {
    const { isLoggedIn, user, logout } = userAuth()

    return (
        <nav className='relative container mx-auto p-6'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-20'>
                    <Link to='/'>
                        <img src={logo} alt='' />
                    </Link>
                    <div className='hidden font-bold lg:flex'>
                        <Link to='/search' className='text-black hover:text-darkBlue'>
                            Search
                        </Link>
                    </div>
                </div>
                {isLoggedIn() ? (
                    <div className='hidden lg:flex items-center space-x-6 text-back'>
                        <div>Welcome, {user?.username}</div>
                        <a
                            onClick={logout}
                            className='px-8 py-3 font-bold rounded text-white bg-lightGreen hover:opacity-70'
                        >
                            Logout
                        </a>
                    </div>
                ) : (
                    <div className='hidden lg:flex items-center space-x-6 text-back'>
                        <Link to='/login' className='hover:text-darkBlue'>
                            Login
                        </Link>
                        <Link
                            to='/register'
                            className='px-8 py-3 font-bold rounded text-white bg-lightGreen hover:opacity-70'
                        >
                            Sign up
                        </Link>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default NavBar
