import React, { BaseSyntheticEvent, FC } from 'react'

interface Props {
    portfolioValue: string
    onPortfolioDelete: (e: BaseSyntheticEvent) => void
}

const DeletePortfolio: FC<Props> = ({ onPortfolioDelete, portfolioValue }) => {
    return (
        <form onSubmit={onPortfolioDelete}>
            <input hidden={true} value={portfolioValue} />
            <button
                type='submit'
                className='block w-full py-3 text-white duration-200 border-2 rounded-lg bg-red-500 hover:text-red-500 hover:bg-white border-red-500'
            >
                X
            </button>
        </form>
    )
}

export default DeletePortfolio
