import axios from 'axios'
import { handleError } from 'src/Helpers/ErrorHandler'
import { UserProfileToken } from 'src/Models/User'

const url = `${process.env.REACT_APP_API_URL}/account`

export const loginApi = async (username: string, password: string) => {
    try {
        const data = await axios.post<UserProfileToken>(`${url}/login`, {
            username,
            password
        })

        return data
    } catch (err) {
        handleError(err)
    }
}

export const registerApi = async (email: string, username: string, password: string) => {
    try {
        const data = await axios.post<UserProfileToken>(`${url}/register`, {
            email,
            username,
            password
        })

        return data
    } catch (err) {
        handleError(err)
    }
}
