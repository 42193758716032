import React, { useEffect, useState } from 'react'
import StockCommentForm from 'src/Components/StockComment/StockCommentForm/StockCommentForm'
import { commentGetApi, commentPostApi } from 'src/Services/CommentService'
import { toast } from 'react-toastify'
import { CommentGet } from 'src/Models/Comment'
import Spinner from 'src/Components/Spinner/Spinner'
import StockCommentList from 'src/Components/StockComment/StockCommentList/StockCommentList'

interface Props {
    symbol: string
}

type FormInput = {
    title: string
    content: string
}

const StockComment = ({ symbol }: Props) => {
    const [comments, setComments] = useState<CommentGet[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getComments()
    }, [])

    const getComments = () => {
        setLoading(true)
        commentGetApi(symbol).then((res) => {
            setLoading(false)
            setComments(res?.data ?? [])
        })
    }

    const handleComment = (input: FormInput) => {
        commentPostApi(input.title, input.content, symbol)
            .then((res) => {
                if (res) {
                    toast.success('Comment created successfully!')
                    getComments()
                }
            })
            .catch((err) => {
                toast.warning(err)
            })
    }

    return (
        <div className='flex flex-col'>
            {loading ? <Spinner /> : <StockCommentList comments={comments} />}
            <StockCommentForm symbol={symbol} handleComment={handleComment} />
        </div>
    )
}

export default StockComment
