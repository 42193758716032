import React from 'react'
import Table from 'src/Components/Table/Table'
import RatioList from 'src/Components/RatioList/RatioList'
import { CompanyKeyMetrics } from 'src/company'
import { testIncomeStatementData } from 'src/Components/Table/testData'

const tableConfig = [
    {
        label: 'Market Cap',
        render: (company: CompanyKeyMetrics) => company.marketCapTTM
    }
]

const DesignGuide = () => {
    return (
        <>
            <h1>FinShark Design Page</h1>
            <h2>This is FinShark design page.</h2>
            <RatioList data={testIncomeStatementData} config={tableConfig} />
            <Table data={testIncomeStatementData} config={tableConfig} />
        </>
    )
}

export default DesignGuide
