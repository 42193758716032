import React from 'react'
import { CommentGet } from 'src/Models/Comment'
import StockCommentItem from 'src/Components/StockComment/StockCommentItem/StockCommentItem'

interface Props {
    comments: CommentGet[]
}

const StockCommentList = ({ comments }: Props) => {
    return (
        <>
            {comments.length > 0 &&
                comments.map((comment, index) => <StockCommentItem key={index} comment={comment} />)}
        </>
    )
}

export default StockCommentList
