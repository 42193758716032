type TDateFormatType = 'date' | 'datetime' | 'time'
export function dateFormat(dateObj: Date, type: TDateFormatType = 'date'): string {
    let dateStr
    const minuteStr = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()

    if (type === 'time') {
        dateStr = `${dateObj.getHours()}h${minuteStr}`
    } else if (type === 'datetime') {
        dateStr = `${dateObj.getHours()}h${minuteStr}, ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`
        // dateStr = `${dateObj.getHours()}h${minuteStr} ngày ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`
    } else {
        dateStr = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`
    }

    return dateStr
}
