import React, { useEffect, useState } from 'react'
import { CompanyIncomeStatement } from 'src/company'
import { useOutletContext } from 'react-router-dom'
import { getIncomeStatement } from 'src/api'
import Table from 'src/Components/Table/Table'
import Spinner from 'src/Components/Spinner/Spinner'
import { formatLargeMonetaryNumber, formatRatio } from 'src/Helpers/NumberFormatting'

const config = [
    {
        label: 'Date',
        render: (company: CompanyIncomeStatement) => company.date
    },
    {
        label: 'Revenue',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.revenue)
    },
    {
        label: 'Cost Of Revenue',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.costOfRevenue)
    },
    {
        label: 'Depreciation',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.depreciationAndAmortization)
    },
    {
        label: 'Operating Income',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.operatingIncome)
    },
    {
        label: 'Income Before Taxes',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.incomeBeforeTax)
    },
    {
        label: 'Net Income',
        render: (company: CompanyIncomeStatement) => formatLargeMonetaryNumber(company.netIncome)
    },
    {
        label: 'Net Income Ratio',
        render: (company: CompanyIncomeStatement) => formatRatio(company.netIncomeRatio)
    },
    {
        label: 'Earnings Per Share',
        render: (company: CompanyIncomeStatement) => formatRatio(company.eps)
    },
    {
        label: 'Earnings Per Diluted',
        render: (company: CompanyIncomeStatement) => formatRatio(company.epsdiluted)
    },
    {
        label: 'Gross Profit Ratio',
        render: (company: CompanyIncomeStatement) => formatRatio(company.grossProfitRatio)
    },
    {
        label: 'Opearting Income Ratio',
        render: (company: CompanyIncomeStatement) => formatRatio(company.operatingIncomeRatio)
    },
    {
        label: 'Income Before Taxes Ratio',
        render: (company: CompanyIncomeStatement) => formatRatio(company.incomeBeforeTaxRatio)
    }
]

const IncomeStatement = () => {
    const ticker = useOutletContext<string>()

    const [incomeStatement, setIncomeStatement] = useState<CompanyIncomeStatement[]>()
    const [serverError, setServerError] = useState<string | null>(null)

    useEffect(() => {
        const incomeStatementFetch = async () => {
            const result = await getIncomeStatement(ticker)
            if (typeof result === 'string') {
                setServerError(result)
            } else if (Array.isArray(result.data)) {
                setServerError(null)
                setIncomeStatement(result.data)
            }
        }

        incomeStatementFetch()
    }, [])

    return (
        <>
            {serverError && <h1>{serverError}</h1>}
            {incomeStatement ? <Table config={config} data={incomeStatement} /> : <Spinner />}
        </>
    )
}

export default IncomeStatement
