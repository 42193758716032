import axios from 'axios'
import {
    CompanyBalanceSheet,
    CompanyCashFlow,
    CompanyComparableData,
    CompanyIncomeStatement,
    CompanyKeyMetrics,
    CompanyProfile,
    CompanySearch,
    CompanyTenK,
    CompanyHistoricalDividend
} from './company'

export const searchCompanies = async (query: string) => {
    try {
        return await axios.get<CompanySearch[]>(
            `https://financialmodelingprep.com/api/v3/search-ticker?query=${query}&limit=10&exchange=NASDAQ&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getCompanyProfile = async (query: string) => {
    try {
        return await axios.get<CompanyProfile[]>(
            `https://financialmodelingprep.com/api/v3/profile/${query}?apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getKeyMetrics = async (query: string) => {
    try {
        return await axios.get<CompanyKeyMetrics[]>(
            `https://financialmodelingprep.com/api/v3/key-metrics-ttm/${query}?limit=40&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getIncomeStatement = async (query: string) => {
    try {
        return await axios.get<CompanyIncomeStatement[]>(
            `https://financialmodelingprep.com/api/v3/income-statement/${query}?limit=40&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getBalanceSheet = async (query: string) => {
    try {
        return await axios.get<CompanyBalanceSheet[]>(
            `https://financialmodelingprep.com/api/v3/balance-sheet-statement/${query}?limit=40&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getCashFlowStatement = async (query: string) => {
    try {
        return await axios.get<CompanyCashFlow[]>(
            `https://financialmodelingprep.com/api/v3/cash-flow-statement/${query}?limit=40&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getComparableData = async (query: string) => {
    try {
        return await axios.get<CompanyComparableData[]>(
            `https://financialmodelingprep.com/api/v4/stock_peers?symbol=${query}&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
            return err.message
        } else {
            console.log('Unexpected error:', err)
            return 'An expected error has occurred.'
        }
    }
}

export const getTenK = async (query: string) => {
    try {
        return await axios.get<CompanyTenK[]>(
            `https://financialmodelingprep.com/api/v3/sec_filings/${query}?type=10-k&page=0&apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('Error message:', err.message)
        } else {
            console.log('Unexpected error:', err)
        }

        return null
    }
}

export const getHistoricalDividend = async (query: string) => {
    try {
        const data = await axios.get<CompanyHistoricalDividend>(
            `https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${query}?apikey=${process.env.REACT_APP_FMP_API_KEY}`
        )
        return data
    } catch (err: any) {
        console.log('error message: ', err.message)
    }
}
