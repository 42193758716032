import React, { FC, BaseSyntheticEvent } from 'react'

interface Props {
    searchValue?: string
    onSearchSubmit: (e: BaseSyntheticEvent) => void
    onSearchValueChange: (e: BaseSyntheticEvent) => void
}

const Search: FC<Props> = ({ searchValue, onSearchSubmit, onSearchValueChange }) => {
    return (
        <section className='relative bg-gray-100'>
            <div className='max-w-4xl mx-auto p-6 space-y-6'>
                <form
                    className='form relative flex flex-col w-full p-10 space-y-4 bg-darkBlue rounded-lg md:flex-row md:space-y-0 md:space-x-3'
                    onSubmit={onSearchSubmit}
                >
                    <input
                        className='flex-1 p-3 border-2 rounded-lg placeholder-black focus:outline-none'
                        id='search-input'
                        placeholder='Search companies'
                        value={searchValue}
                        onChange={onSearchValueChange}
                    ></input>
                    <button
                        type='submit'
                        className='px-8 py-3 font-bold rounded text-white bg-lightGreen hover:opacity-70'
                    >
                        Search
                    </button>
                </form>
            </div>
        </section>
    )
}

export default Search
