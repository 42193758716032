import React, { BaseSyntheticEvent, FC } from 'react'
import Card from 'src/Components/Card/Card'
import { CompanySearch } from 'src/company'
import { v4 as uuidv4 } from 'uuid'

interface Props {
    searchResult: CompanySearch[]
    onPortfolioCreate: (e: BaseSyntheticEvent) => void
}

const CardList: FC<Props> = ({ searchResult, onPortfolioCreate }) => {
    return (
        <>
            {searchResult.length > 0 ? (
                searchResult.map((searchItem) => {
                    return (
                        <Card
                            key={uuidv4()}
                            id={searchItem.symbol}
                            searchItem={searchItem}
                            onPortfolioCreate={onPortfolioCreate}
                        />
                    )
                })
            ) : (
                <p className='mb-3 mt-3 text-xl font-semibold text-center md:text-xl'>No results!</p>
            )}
        </>
    )
}

export default CardList
