import React from 'react'
import 'src/App.css'
import NavBar from 'src/Components/NavBar/NavBar'
import AppRoutes from 'src/Routes/AppRoutes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { UserProvider } from 'src/Context/userAuth'

function App() {
    return (
        <UserProvider>
            <NavBar />
            <AppRoutes />
            <ToastContainer />
        </UserProvider>
    )
}

export default App
