import { UserProfile } from 'src/Models/User'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginApi, registerApi } from 'src/Services/AuthService'
import { toast } from 'react-toastify'
import axios from 'axios'

type UserContextType = {
    user: UserProfile | null
    token: string | null
    registerUser: (email: string, username: string, password: string) => void
    login: (username: string, password: string) => void
    logout: () => void
    isLoggedIn: () => boolean
}

type Props = { children: ReactNode }

const UserContext = createContext<UserContextType>({} as UserContextType)

export const UserProvider = ({ children }: Props) => {
    const navigate = useNavigate()
    const [user, setUser] = useState<UserProfile | null>(null)
    const [token, setToken] = useState<string | null>(null)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        const user = localStorage.getItem('user')
        const token = localStorage.getItem('token')

        if (user && token) {
            setUser(JSON.parse(user))
            setToken(token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        }

        setIsReady(true)
    }, [])

    const registerUser = async (email: string, username: string, password: string) => {
        await registerApi(email, username, password)
            .then((res) => {
                if (res) {
                    const userObj = {
                        username: res.data.username,
                        email: res.data.email
                    }
                    localStorage.setItem('user', JSON.stringify(userObj))
                    localStorage.setItem('token', res.data.token)

                    setUser(userObj)
                    setToken(res.data.token)

                    toast.success('Register success')
                    navigate('/search')
                }
            })
            .catch((err) => {
                toast.warning('Server error occurred')
            })
    }

    const login = async (username: string, password: string) => {
        await loginApi(username, password)
            .then((res) => {
                if (res) {
                    const userObj = {
                        username: res.data.username,
                        email: res.data.email
                    }
                    localStorage.setItem('user', JSON.stringify(userObj))
                    localStorage.setItem('token', res.data.token)

                    setUser(userObj)
                    setToken(res.data.token)

                    toast.success('Login success')
                    navigate('/search')
                }
            })
            .catch((err) => {
                toast.warning('Server error occurred')
            })
    }

    const isLoggedIn = () => {
        return !!user
    }

    const logout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')

        setUser(null)
        setToken(null)

        navigate('/')
    }

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <UserContext.Provider value={{ user, token, isLoggedIn, registerUser, login, logout }}>
            {isReady ? children : null}
        </UserContext.Provider>
    )
}

export const userAuth = () => useContext(UserContext)
