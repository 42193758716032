import React, { useEffect, useState } from 'react'
import { CompanyBalanceSheet } from 'src/company'
import { useOutletContext } from 'react-router-dom'
import { getBalanceSheet } from 'src/api'
import RatioList from 'src/Components/RatioList/RatioList'
import Spinner from 'src/Components/Spinner/Spinner'
import { formatLargeMonetaryNumber } from 'src/Helpers/NumberFormatting'

const config = [
    {
        label: <div className='font-bold'>Total Assets</div>,
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.totalAssets)
    },
    {
        label: 'Current Assets',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.totalCurrentAssets)
    },
    {
        label: 'Total Cash',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.cashAndCashEquivalents)
    },
    {
        label: 'Property & equipment',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.propertyPlantEquipmentNet)
    },
    {
        label: 'Intangible Assets',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.intangibleAssets)
    },
    {
        label: 'Long Term Debt',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.longTermDebt)
    },
    {
        label: 'Total Debt',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.otherCurrentLiabilities)
    },
    {
        label: <div className='font-bold'>Total Liabilites</div>,
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.totalLiabilities)
    },
    {
        label: 'Current Liabilities',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.totalCurrentLiabilities)
    },
    {
        label: 'Long-Term Debt',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.longTermDebt)
    },
    {
        label: 'Long-Term Income Taxes',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.otherLiabilities)
    },
    {
        label: "Stakeholder's Equity",
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.totalStockholdersEquity)
    },
    {
        label: 'Retained Earnings',
        render: (company: CompanyBalanceSheet) => formatLargeMonetaryNumber(company.retainedEarnings)
    }
]

const BalanceSheet = () => {
    const ticker = useOutletContext<string>()

    const [balanceSheet, setBalanceSheet] = useState<CompanyBalanceSheet>()
    const [serverError, setServerError] = useState<string | null>(null)

    useEffect(() => {
        const getData = async () => {
            const result = await getBalanceSheet(ticker)
            if (typeof result === 'string') {
                setServerError(result)
            } else if (Array.isArray(result.data)) {
                setServerError(null)
                setBalanceSheet(result.data[0])
            }
        }

        getData()
    }, [])

    return (
        <>
            {serverError && <h1>{serverError}</h1>}
            {balanceSheet ? <RatioList config={config} data={balanceSheet} /> : <Spinner />}
        </>
    )
}

export default BalanceSheet
