import React from 'react'
import Hero from 'src/Components/Hero/Hero'

const HomePage = () => {
    return (
        <>
            <Hero />
        </>
    )
}

export default HomePage
