import React, { BaseSyntheticEvent, FC } from 'react'
import DeletePortfolio from 'src/Components/Portfolio/DeletePortfolio/DeletePortfolio'
import { Link } from 'react-router-dom'
import { PortfolioGet } from 'src/Models/Portfolio'

interface Props {
    portfolioValue: PortfolioGet
    onPortfolioDelete: (e: BaseSyntheticEvent) => void
}

const CardPortfolio: FC<Props> = ({ onPortfolioDelete, portfolioValue }) => {
    return (
        <div className='flex flex-col w-full p-8 space-y-4 text-center rounded-lg shadow-lg md:w-1/3'>
            <Link to={`/company/${portfolioValue.symbol}/company-profile`} className='pt-6 text-xl font-bold'>
                {portfolioValue.symbol}
            </Link>
            <DeletePortfolio portfolioValue={portfolioValue.symbol} onPortfolioDelete={onPortfolioDelete} />
        </div>
    )
}

export default CardPortfolio
