import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CompanyProfile } from 'src/company'
import { getCompanyProfile } from 'src/api'
import Sidebar from 'src/Components/Sidebar/Sidebar'
import CompanyDashboard from 'src/Components/CompanyDashboard/CompanyDashboard'
import Tile from 'src/Components/Tile/Tile'
import Spinner from 'src/Components/Spinner/Spinner'
import ComparableFinderList from 'src/Components/ComparableFinder/ComparableFinderList/ComparableFinderList'
import TenKFinder from 'src/Components/TenKFinder/TenKFinder'

const CompanyPage = () => {
    const { ticker } = useParams()
    const [company, setCompany] = useState<CompanyProfile | null>(null)
    const [serverError, setServerError] = useState<string | null>(null)

    useEffect(() => {
        const getProfileInitial = async () => {
            const result = await getCompanyProfile(ticker!)
            if (typeof result === 'string') {
                setServerError(result)
            } else if (Array.isArray(result.data)) {
                setServerError(null)
                setCompany(result.data[0])
            }
        }

        getProfileInitial()
    }, [])

    return (
        <>
            {serverError && <h1>{serverError}</h1>}
            {company ? (
                <div className='w-full relative flex ct-docs-disable-sidebar-content overflow-x-hidden'>
                    <Sidebar />
                    <CompanyDashboard ticker={ticker!}>
                        <Tile label='Company name' value={company.companyName} />
                        <Tile label='Price' value={'$' + company.price.toString()} />
                        <Tile label='DCF' value={'$' + company.dcf.toString()} />
                        <Tile label='Sector' value={company.sector} />
                        {/*<ComparableFinderList ticker={company.symbol} />*/}
                        <TenKFinder ticker={company.symbol} />
                        <p className='bg-white shadow rounded text-medium text-gray-900 p-3 mt-1 m-4'>
                            {company.description}
                        </p>
                    </CompanyDashboard>
                </div>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default CompanyPage
