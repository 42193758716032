import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from 'src/Pages/HomePage/HomePage'
import SearchPage from 'src/Pages/SearchPage/SearchPage'
import CompanyPage from 'src/Pages/CompanyPage/CompanyPage'
import Page404 from 'src/Pages/404'
import CompanyProfile from 'src/Components/CompanyProfile/CompanyProfile'
import IncomeStatement from 'src/Components/IncomeStatement/IncomeStatement'
import DesignGuide from 'src/Pages/DesignGuide/DesignGuide'
import BalanceSheet from 'src/Components/BalanceSheet/BalanceSheet'
import CashFlowStatement from 'src/Components/CashFlowStatement/CashFlowStatement'
import HistoricalDividend from 'src/Components/HistoricalDividend/HistoricalDividend'
import LoginPage from 'src/Pages/LoginPage/LoginPage'
import RegisterPage from 'src/Pages/RegisterPage/RegisterPage'
import ProtectedRoute from 'src/Routes/ProtectedRoute'

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='' element={<HomePage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route
                path='search'
                element={
                    <ProtectedRoute>
                        <SearchPage />
                    </ProtectedRoute>
                }
            />
            <Route path='design-guide' element={<DesignGuide />} />
            <Route
                path='company/:ticker'
                element={
                    <ProtectedRoute>
                        <CompanyPage />
                    </ProtectedRoute>
                }
            >
                <Route path='company-profile' element={<CompanyProfile />} />
                <Route path='income-statement' element={<IncomeStatement />} />
                <Route path='balance-sheet' element={<BalanceSheet />} />
                <Route path='cash-flow-statement' element={<CashFlowStatement />} />
                <Route path='historical-dividend' element={<HistoricalDividend />} />
            </Route>
            <Route path='*' element={<Page404 />} />
        </Routes>
    )
}
export default AppRoutes
