import React, { useEffect, useState } from 'react'
import { CompanyTenK } from 'src/company'
import { getTenK } from 'src/api'
import TenKFinderItem from 'src/Components/TenKFinder/TenKFinderItem/TenKFinderItem'
import Spinner from 'src/Components/Spinner/Spinner'

interface Props {
    ticker: string
}

const TenKFinder = ({ ticker }: Props) => {
    const [companyData, setCompanyData] = useState<CompanyTenK[]>()

    useEffect(() => {
        const getData = async () => {
            const result = await getTenK(ticker)
            setCompanyData(result?.data)
        }
        getData()
    }, [ticker])

    return (
        <div className='inline-flex rounded-md shadow-sm m-4'>
            {companyData ? (
                companyData?.slice(0, 5).map((tenK, index) => <TenKFinderItem key={index} tenK={tenK} />)
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default TenKFinder
