import axios from 'axios'
import { handleError } from 'src/Helpers/ErrorHandler'
import { PortfolioGet, PortfolioPost } from 'src/Models/Portfolio'

const url = `${process.env.REACT_APP_API_URL}/portfolios`

export const portfolioAddApi = async (symbol: string) => {
    try {
        const data = await axios.post<PortfolioPost>(`${url}?symbol=${symbol}`)
        return data
    } catch (err) {
        handleError(err)
    }
}

export const portfolioDeleteApi = async (symbol: string) => {
    try {
        const data = await axios.delete<PortfolioPost>(`${url}?symbol=${symbol}`)
        return data
    } catch (err) {
        handleError(err)
    }
}

export const portfolioGetApi = async () => {
    try {
        const data = await axios.get<PortfolioGet[]>(url)
        return data
    } catch (err) {
        handleError(err)
    }
}
